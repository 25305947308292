import React from 'react'
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import "./Pages.scss"
import Bg from "../images/bg-3.jpeg"
import Content from "../content/AboutContent.json"
import { Col, Container, Row } from 'react-bootstrap'
import Art from "../images/art.jpeg"
import Frank from "../images/frank.jpeg"
import Gwain from "../images/gwain.jpg"
import Ron from "../images/ron.jpg"
import PersonIcon from "../images/person-icon.png"

export default function About() {

  const bioPhoto = name => {
    switch(name) {
      case "art": 
        return Art
      case "frank":
        return Frank
      case "gwain":
        return Gwain
      case "ron":
        return Ron
      default:
        return PersonIcon
    }
  }

  return (
    <div>
      <Headers/>

      <img className="parallax" src={Bg} alt='Background'/>
      <div className='banner-cover'></div>
      <div className='banner'>
        <h1 className='display-3 text-white animate__animated animate__fadeInDown'><b>{Content.header}</b></h1>
      </div>


      <Container fluid className="bg-white pb-5 pt-2" style={{padding: "0 15%", boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <h6>{Content.subheader}</h6>
        {Content.bios.map((info, key) => (
          <Row key={key} className={'my-5 pt-2 content-list ' + (key === Content.bios.length - 1 ? "last" : "")}>
            <Col className='' lg={4}>
              <img className='bio-pic mb-4' src={bioPhoto(info['image-name'])} alt={info.name}/>
            </Col>
            <Col lg={8}>
              <div className={"" }>
                <h5>{info.name}</h5>
                <ul>
                  {info.p.map((bio, key) => (
                    <li key={key}><p>{bio}</p></li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
        ))}
      </Container>
      <Footer/>
    </div>
  )
}
