import React from 'react'
import "./Pages.scss"
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import Bg from "../images/bg-1.jpeg"
import { Container } from 'react-bootstrap'
import Content from "../content/TechnologiesContent.json"

export default function Technologies() {
  return (
    <div>
      <Headers />

      <img className="parallax" src={Bg} alt='Background'/>
      <div className='banner-cover'></div>
      <div className='banner'>
        <h1 className='display-3 text-white animate__animated animate__fadeInDown'><b>{Content.header}</b></h1>
      </div>

      <Container fluid className="bg-white pb-5 pt-2" style={{padding: "0 15%", boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <h6>{Content.subheader}</h6>
        <p>{Content.p1}</p>
        <p>{Content.p2}</p>
      </Container>

      <Container fluid className="bg-light py-5" style={{padding: "0 15%"}}>
        {Content.items.map((info, key) => (
          <div key={key} className={"content-list " + (key === Content.items.length - 1 ? "last" : "")}>
            <h5>{info.title}</h5>
            <ul className='pt-1'>
              {info.list.map((l, key) => (
                <li key={key}><p>{l.p}</p></li>
              ))}
            </ul>
          </div>
        ))}
      </Container>

      <Footer />
    </div>
  )
}