
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from "./pages/Home";
import About from "./pages/About";
import Technologies from "./pages/Technologies";
import Applications from "./pages/Applications";
import Contact from "./pages/Contact";
import './App.scss';
import 'animate.css';

function App() {

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/technologies" element={<Technologies/>} />
          <Route path="/applications" element={<Applications/>} />
          <Route path="/contact" element={<Contact/>} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
