import React from 'react'
import "./Pages.scss"
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import Bg from "../images/bg-4.jpeg"
import Content from "../content/ContactContent.json"
import { Container } from 'react-bootstrap'
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Contact() {

  const itemIcon = item => {
    switch(item) {
      case "email":
        return faEnvelope
      case "phone":
        return faPhone
      case "address":
        return faLocationDot
      default:
        return
    }
  }
  return (
    <div>
      <Headers />

      <img className="parallax" src={Bg} alt='Background'/>
      <div className='banner-cover'></div>
      <div className='banner'>
        <h1 className='display-3 text-white animate__animated animate__fadeInDown'><b>{Content.header}</b></h1>
      </div>

      <Container fluid className="bg-white pb-5 pt-2" style={{padding: "0 15%", boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <h6>{Content.header}</h6>
        {Content.items.map((info, key) => (
          <div key={key} className='d-flex align-items-center my-4' style={{fontSize: "calc(14px + 0.2vi)"}}>
            <FontAwesomeIcon className='me-3' icon={itemIcon(info.item)} fontSize={24} width={50}/>
            {info.info}
          </div>
        ))}
        <iframe
          className='mt-3'
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19252.232037119433!2d-117.1185522623063!3d32.77759588945454!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x70ef3727c978d6e1!2sTrabus%20Technologies!5e0!3m2!1sen!2sus!4v1625000112658!5m2!1sen!2sus"
          height={450}
          style={{border: 0, width: "100%", borderRadius: 10}}
          title="Trabus Technologies Google Map"
          loading="lazy">
        </iframe>
      </Container>

      <Footer />
    </div>
  )
}
