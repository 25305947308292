import React from 'react'
import "./Pages.scss"
import Headers from '../components/Headers'
import Footer from '../components/Footer'
import Bg from "../images/bg-2.jpeg"
import Content from "../content/ApplicationsContent.json"
import { Container } from 'react-bootstrap'

export default function Applications() {
  return (
    <div>
      <Headers />

      <img className="parallax" src={Bg} alt='Background'/>
      <div className='banner-cover'></div>
      <div className='banner'>
        <h1 className='display-3 text-white animate__animated animate__fadeInDown'><b>{Content.header}</b></h1>
      </div>

      <Container fluid className="bg-white pb-5 pt-2" style={{padding: "0 15%", boxShadow: 'inset 0 5px 5px #bebebe'}}>
        <br/>
        {Content.items.map((info, key) => (
          <div key={key} className={"content-list " + (key === Content.items.length - 1 ? "last" : "")}>
            <h5>{info.title}</h5>
            <p>{info.p}</p>
          </div>
        ))}
      </Container>

      <Footer />
    </div>
  )
}
