import React, { useState } from 'react'
import TrabusLogo from "../images/trabus_logo_short_white.png"
import SEPPLogo from "../images/sepp-logo-white.png"
import { Nav, Navbar } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import "./Headers.scss"

export default function Headers() {
  const navigate = useNavigate()
  const [windowOnScroll, setWindowOnScroll] = useState("transparent")
  const changeNavbarColor = () => {
    setWindowOnScroll(window.scrollY > 1 ? 'primary' : 'transparent')
  }

  window.addEventListener('scroll', changeNavbarColor)

  const backToTop = link => {
    navigate(link)
    window.scrollTo(0,0)
  }

  return (
    <Navbar bg={windowOnScroll} expand="lg" className="navbar-dark sticky-top">
      <Navbar.Brand href="/">
        <div className="logo-wrap">
          <img className="navbar-logo mt-1 ms-3" src={TrabusLogo} alt="Trabus Logo" />
          <div className="vertical-bar mx-3"></div>
          <img className="navbar-logo sepp" src={SEPPLogo} alt="SEPP logo" />
        </div>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        <Nav activeKey={window.location.pathname} className="ms-auto me-3 d-flex align-items-center">
          <div className={'nav-item ' + (window.location.pathname === "/" ? "active" : "")} onClick={() => backToTop("/")}>Home</div>
          <div className={'nav-item ' + (window.location.pathname === "/about" ? "active" : "")} onClick={() => backToTop("/about")}>About Us</div>
          <div className={'nav-item ' + (window.location.pathname === "/technologies" ? "active" : "")} onClick={() => backToTop("/technologies")}>Technologies</div>
          <div className={'nav-item ' + (window.location.pathname === "/applications" ? "active" : "")} onClick={() => backToTop("/applications")}>Applications</div>
          <div className='nav-item contact-btn' onClick={() => backToTop("/contact")}>Contact</div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
