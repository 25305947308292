import React, { useRef } from 'react'
import SEPPColorLogo from "../images/sepp-logo.jpg"
import SEPPLogo from "../images/sepp-logo-white.png"
import SEPPContent from "../content/SEPPContent.json"
import bgImg from "../images/bg.jpeg"
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons"
import Footer from '../components/Footer'
import Headers from '../components/Headers'
import "./Pages.scss"

export default function Home() {
  const learnMoreRef = useRef(null)

  return (
    <div>
      <Headers/> 

      <div className="banner-content-wrap">
        <div className="banner-content container">
          <img className="sepp-logo" src={SEPPLogo} alt="SEPP logo" />
          <h1 className="my-4 header">Connecting Communities With <br/>Media-Rich <span style={{color: "#FFC000"}}>Peer-to-Peer</span></h1>
          <p style={{width: '75%', fontWeight: 500}}>{SEPPContent.description}</p>

          <FontAwesomeIcon className="down-arrow" icon={faAngleDoubleDown} onClick={() => learnMoreRef.current.scrollIntoView()}/>
        </div>
      </div>

      <img className='parallax home' src={bgImg} alt="Background" />
      <div className='banner-cover home'></div>

      <Container fluid className="bg-white py-4" style={{padding: "0 15%", boxShadow: 'inset 0 5px 5px #bebebe'}}>
        
        <Row ref={learnMoreRef} className="video-wrap">
          <Col lg={4} className="d-flex justify-content-center">
            <img className="sepp-logo color my-4" src={SEPPColorLogo} alt="SEPP logo"/>
          </Col>

          <Col lg={8}>
            <div style={{position: 'relative', paddingTop: '56.25%', width: '100%' }}>
              <iframe
                  src="https://www.youtube-nocookie.com/embed/pDPlVerbVRo?rel=0"
                  title="Trabus Technologies SEPP Video"
                  frameBorder="0"
                  allowFullScreen
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  style={{
                    position: 'absolute',
                    top: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '10px',
                  }}
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>

      <Container fluid className="bg-light py-4" style={{padding: "0 15%"}}>
        {SEPPContent.items.map( (item, index) => (
            <div className={"content-list " + (index === SEPPContent.items.length-1 ? "last" : "")} key={index}>
                <h5>{item.title}</h5>
                <p>{item.description}</p>
                <ul>
                    {item.list.map( (list, index) => (
                        <li className="my-2" key={index}>{list}</li>
                    ))}
                </ul>
            </div>
        ))}
      </Container>


      <Footer />
    </div>
  )
}
