import React from 'react'
import { Col, Container, Row } from "react-bootstrap";
import TrabusIcon from "../images/trabus_icon.png"
import SEPPLogo from "../images/sepp-logo-white.png"
import { faFacebookF, faLinkedinIn, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from 'react-router-dom';
import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons'
import ContactContent from "../content/ContactContent.json"
import "./Footer.scss"

export default function Footer() {
  const navigate = useNavigate()

  const itemIcon = item => {
    switch(item) {
      case "email":
        return faEnvelope
      case "phone":
        return faPhone
      case "address":
        return faLocationDot
      default:
        return
    }
  }

  const backToTop = link => {
    navigate(link)
    window.scrollTo(0,0)
  }

  return (
    <Container className='p-0' fluid>
      <div id="footer">
          <Container className="text-center my-4 p-0">
              <Row className="justify-content-between">
                  <Col lg={3}>
                    <div 
                        className='d-flex align-items-center mb-3 justify-content-center' 
                        style={{cursor: "pointer"}} 
                        onClick={() => backToTop("/")}>
                        <img
                            alt="Trabus logo"
                            src={TrabusIcon}
                            height={90}
                            className="mb-0"
                        />
                        <div style={{
                            width: 2,
                            height: 80,
                            margin: '0 25px',
                            backgroundColor: 'white'
                        }}></div>
                        <img
                            alt="Trabus logo"
                            src={SEPPLogo}
                            height={100}
                            className="m-0"
                        />
                    </div>
                      <p style={{fontWeight: 500}}>Impact Through Technical Innovation</p>
                  </Col>
                  <Col lg={3}>
                      <h6 className="my-3" style={{color: 'white', fontWeight: 600}}>COMPANY</h6>
                          <div className="d-flex flex-column" style={{fontWeight: 500}}>
                              <div className='company-link' onClick={() => backToTop("/about")}>About Us</div>
                              <div className='company-link' onClick={() => backToTop("/technologies")}>Technologies</div>
                              <div className='company-link' onClick={() => backToTop("/applications")}>Applications</div>
                              <div className='company-link' onClick={() => backToTop("/contact")}>Contact Us</div>
                          </div>
                  </Col>
                  <Col lg={3}>
                      <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>SOCIAL MEDIA</h6>
                      <div className="d-flex justify-content-center">
                          <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                              target="_blank" rel="noopener noreferrer"
                              href="https://www.facebook.com/Trabus-Technologies-143543429114409/">
                              <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faFacebookF} />
                          </a>
                          <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                              target="_blank" rel="noopener noreferrer"
                              href="https://www.linkedin.com/company/trabus-technologies">
                              <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faLinkedinIn} />
                          </a>
                          <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                              target="_blank" rel="noopener noreferrer"
                              href="https://www.youtube.com/channel/UCttreg0QOabPfbfYPh3AHKA">
                              <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faYoutube} />
                          </a>
                          <a className="d-flex mx-1 justify-content-center align-items-center social-icon"
                              target="_blank" rel="noopener noreferrer"
                              href="https://twitter.com/Trabus_Tech">
                              <FontAwesomeIcon className="" style={{fontSize: "20px", color: "white"}} icon={faTwitter} />
                          </a>
                      </div>
                  </Col>
                  <Col lg={3}>
                      <h6 className="my-3" style={{color: 'white', fontWeight: 500}}>CONTACT US</h6>
                      <div className="d-flex flex-column justify-content-center">
                          {ContactContent.items.map((info, key) => (
                            <div key={key} className='mb-3'>
                                <FontAwesomeIcon className='' icon={itemIcon(info.item)} fontSize={18} width={50}/>
                                {info.info}
                            </div>
                          ))}
                      </div>
                  </Col>
              </Row>
          </Container>
          <div className="w-100 text-center">
              <small className="copyright">Copyright &copy; {new Date().getFullYear()} TRABUS Technologies. All Rights Reserved.</small>
          </div>
      </div>
    </Container>
  )
}
